import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import i18n from "i18next"
import moment from "moment"

import "./StudentCard.css"

import { Title, Icon } from "../Common"
import Routes from "../../routes"

import Session from "../../helpers/SessionHelper"

const StudentCard = ({ studentCurriculumTranslated, studentFullName, history }) => {
  return(
  <Col xs={ 12 } className="student-card__container">
    <Col xs={ 12 } sm={ 6 } className="student-card__student-info">
      <Title size={ 14 }>
        <Icon iconName="id-card" iconSize={ 16 } style={ { marginRight: 10 } } />
        {i18n.t("spa:card.student_card.about")}
      </Title>
      <Title size={ 13 }>{studentFullName}</Title>
      <span style={ { color: "#808384" } }>{studentCurriculumTranslated}</span>
    </Col>
    {!Session.isRecruiter() && (
      <Col xs={ 6 } sm={ 6 } className="student-card__history">
        <Title size={ 14 }>{i18n.t("spa:card.student_card.history.title")}</Title>
        {history.length > 0 && (
          <ul>
            {history.map(elem => (
              <li key={ elem.id }>
                <Link to={ Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", elem.id) }>
                  {`[${i18n.t(`spa:card.specific_agreement_state.${elem.state}.school_admin`)}]${elem.alteration_status ? `[${i18n.t(`spa:card.specific_agreement_alteration_status.${elem.alteration_status}`)}]` : ''} ${elem.company_name}, ${
                    elem.job_title
                  }, ${elem.student_curriculum_value_in_user_language} ${i18n.t("spa:card.student_card.history.date_range", {
                    startDate: moment(elem.job_started_at).format("L"),
                    endDate: moment(elem.job_real_ended_at || elem.job_ended_at).format("L"),
                  })}`}
                </Link>
              </li>
            ))}
          </ul>
        )}
        {history.length === 0 && (
          <span className="student-card__list-placeholder">{i18n.t("spa:card.student_card.history.placeholder")}</span>
        )}
      </Col>
    )}
  </Col>
)}

StudentCard.propTypes = {
  studentFullName: PropTypes.string,
  studentCurriculumTranslated: PropTypes.string,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company_name: PropTypes.string,
      job_started_at: PropTypes.string,
      job_ended_at: PropTypes.string,
      job_title: PropTypes.string,
      state: PropTypes.string,
    })
  ),
}

StudentCard.defaultProps = {
  studentFullName: "",
  studentCurriculumTranslated: "",
  history: [],
}

export default StudentCard
