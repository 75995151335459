import { Component, Children } from "react"
import PropTypes from "prop-types"

import Session from "../helpers/SessionHelper"

export default class ThemeProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static childContextTypes = {
    primaryColor: PropTypes.func,
  }

  getChildContext() {
    return {
      primaryColor: () => Session.primaryColor,
    }
  }

  render() {
    return Children.only(this.props.children)
  }
}
